import React, { useContext, useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { theme } from "../theme/theme";
import { useTagStringToArray } from "../hooks/useTagStringToArray";

export const Ticker = () => {
  const { allWorks, home } = useStaticQuery(graphql`
    query {
      allWorks: allDatoCmsWork(sort: { order: ASC, fields: position }) {
        edges {
          node {
            tag
          }
        }
      }
      home: datoCmsHome {
        ticker
      }
    }
  `);

  const tags = allWorks.edges.map(({ node }) => node.tag).join();
  const tagsArr = new Set(useTagStringToArray(tags));

  return (
    <Div className="Ticker flex col align-center container padding">
      <div class="ticker-wrap">
        <div class="ticker">
          <div class="ticker__item">{home.ticker.repeat(10)}</div>
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  .ticker-wrap {
    //position: fixed;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    height: 65px;
    border-top: 1px solid #000;
    //padding-left: 100%;
    box-sizing: content-box;
  }
  .ticker-wrap .ticker {
    display: inline-block;
    height: 65px;
    line-height: 65px;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 300s;
    animation-duration: 300s;
  }
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 24px;
    color: #000;
  }
`;
