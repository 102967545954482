import React, { useContext, useEffect, useState } from "react";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Layout } from "../components/Layout";
import { WorkGrid } from "../components/WorkGrid";
import { WorkList } from "../components/WorkList";
import styled from "styled-components";
import { theme } from "../theme/theme";
import { Ticker } from "../components/Ticker";

const Home = ({ pageContext }) => {
  const { page, allWorks } = pageContext.data;

  const [showList, setShowList] = useState(false);

  const viewMode = (e, isList) => {
    e.preventDefault();
    setShowList(isList);
  };

  return (
    <>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Layout>
        <Main className="flex align-center col">
          <section className="Hero flex col">
            <div className="flex col container padding">
              <div dangerouslySetInnerHTML={{ __html: page.title }} />
            </div>
          </section>
          <section className="Main flex col container align-center padding">
            <div className="Filters">
              <button
                className={showList && "active"}
                onClick={(e) => viewMode(e, false)}
              >
                Preview
              </button>
              <button
                className={!showList && "active"}
                onClick={(e) => viewMode(e, true)}
              >
                List
              </button>
            </div>
          </section>
          {!showList && <WorkGrid />}
          {showList && <WorkList data={allWorks.edges} />}

          <Ticker />
        </Main>
      </Layout>
    </>
  );
};

export default Home;

const Main = styled.main`
  h1 {
    max-width: 1000px;
    padding-top: 30px;
  }
  .Main {
  }
  .Filters {
    display: flex;
    align-self: flex-start;
    padding-top: 35px;
    button {
      color: #000;
      margin-right: 10px;
      padding-right: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 0;
      text-align: left;
      &.active {
        opacity: 0.4;
      }
    }
  }
`;
