import React, { useContext, useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { theme } from "../theme/theme";
import Fade from "react-reveal/Fade";

export const WorkGrid = () => {
  const query = useStaticQuery(graphql`
    query {
      allWorks: allDatoCmsWork(sort: { order: ASC, fields: position }) {
        edges {
          node {
            slug
            seoMetaTags {
              tags
            }
            client
            tag
            year
            image {
              gatsbyImageData(
                width: 2400
                placeholder: BLURRED
                forceBlurhash: false
              )
              alt
            }
            featureImage {
              gatsbyImageData(
                width: 1000
                placeholder: BLURRED
                forceBlurhash: false
              )
              url
              format
              alt
            }
            gallery {
              gatsbyImageData(
                width: 2400
                placeholder: BLURRED
                forceBlurhash: false
              )
              alt
            }
            collage {
              images {
                gatsbyImageData(
                  width: 2400
                  placeholder: BLURRED
                  forceBlurhash: false
                )
                alt
              }
            }
            information
          }
        }
      }
    }
  `);

  const { allWorks } = query;

  return (
    <Section className="ArticleGrid flex col align-center container padding">
      <div className="Grid">
        {allWorks.edges.map((i, index) => {
          let tags = [];
          if (i.node.tag) {
            let arr = i.node.tag.split(/[,]+/);
            tags = arr.map((string) => {
              return string.trim();
            });
          }

          let normalizedGifUrl = "";
          if (i.node.featureImage.format === "gif") {
            const newUrl = i.node.featureImage.url.split("?");
            normalizedGifUrl = newUrl[0];
          }

          return (
            <Link className="Item flex col" to={`/work/${i.node.slug}`}>
              <div className="imgBox">
                {i.node.featureImage.format === "gif" && (
                  <img className="itemImg" src={normalizedGifUrl} />
                )}

                {i.node.featureImage.format !== "gif" && (
                  <GatsbyImage
                    className="itemImg"
                    alt={i.node.featureImage?.alt || "Feature image"}
                    image={i.node.featureImage?.gatsbyImageData}
                  />
                )}

                <div className="Overlay" />
              </div>
              <div
                className="flex"
                css={`
                  margin-top: 20px;
                `}
              >
                <h3 data-content={i.node.client}>{i.node.client}</h3>
                <span className="tag">{tags.join(", ")}</span>
              </div>
            </Link>
          );
        })}
      </div>
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  min-height: 400px;
  .Grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    gap: 40px;
    padding-top: 45px;
    padding-bottom: 40px;
    @media (max-width: 800px) {
      grid-template-columns: repeat(1, minmax(200px, 1fr));
    }
  }

  .Item {
    transition: all 0.2s;
    padding-bottom: 20px;
    .itemImg {
      height: 380px;
      @media (max-width: 1350px) {
        height: 340px;
      }
      @media (max-width: 1150px) {
        height: 300px;
      }
      @media (max-width: 400px) {
        height: 200px;
      }
    }
    :hover h3::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
    .imgBox {
      position: relative;
      overflow: hidden;
    }
    .Overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
      transition: opacity 0.2s;
    }
    :hover .Overlay {
      opacity: 0.05;
    }
    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: inline-block;
      width: 30%;
      position: relative;

      &::before {
        position: absolute;
        content: attr(data-content);
        color: white;
        clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
        transition: clip-path 275ms ease;
        background: black;
      }
    }
    .tag {
      font-size: 14px;
      line-height: 17px;
    }
    h3 span,
    .tag {
      position: relative;
      transition: color 0.5s;
    }
    &:hover {
      @media (min-width: 600px) {
        h3 span,
        .tag {
          //color: #fff;
        }
      }
    }
  }
`;
